import { createApp } from "vue";
import App from "./App.vue";
import router from "./router";
import store from "./store";
import http from "./request/http";  //封装axios
import 'amfe-flexible';
import Vant from 'vant' //全局引用element Plus
import '../node_modules/vant/lib/index.css'

const app = createApp(App)
app.use(store).use(router).use(http).use(Vant);
app.mount("#app")