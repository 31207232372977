import { createRouter, createWebHistory, RouteRecordRaw } from "vue-router";

const routes: Array<RouteRecordRaw> = [
  {
    path: '/Login',
    name: 'Login',
    meta: { title: '登录' },
    component: () => import('@/views/Login.vue')
  },

  {
    path: '/',
    name: 'Index',
    meta: { isAuth: true, title: '首页' },
    component: () => import('@/views/Index.vue'),
  },

  {
    path: '/Setting',
    name: 'Setting',
    meta: { isAuth: true, title: '设置' },
    component: () => import('@/views/Setting.vue'),
  },

  {
    path: '/ToDoList',
    name: 'ToDoList',
    meta: { isAuth: true, title: '待办事宜' },
    component: () => import('@/views/ToDoList.vue'),
  },{
    path: '/FlowCommission',
    name: 'FlowCommission',
    meta: { isAuth: true, title: '待办流程' },
    component: () => import('@/views/FlowCommission.vue'),
  },
  {
    path: '/MyRequest',
    name: 'MyRequest',
    meta: { isAuth: true, title: '我的请求' },
    component: () => import('@/views/MyRequest.vue'),
  },
  {
    path: '/DoneBusiness',
    name: 'DoneBusiness',
    meta: { isAuth: true, title: '已办事宜' },
    component: () => import('@/views/DoneBusiness.vue'),
  },
  {
    path: '/NewCutPipe',
    name: 'NewCutPipe',
    meta: { isAuth: true, title: '截管管理' ,},
    component: () => import('@/views/NewCutPipe.vue'),
  },
  {
    path: '/AddCutPipe',
    name: 'AddCutPipe',
    meta: { isAuth: true, title: '新增截管' },
    component: () => import('@/views/AddCutPipe.vue'),
  },
  {
    path: '/OwningPipe',
    name: 'OwningPipe',
    meta: { isAuth: true, title: '所属截管' },
    component: () => import('@/views/OwningPipe.vue'),
  },
  {
    path: '/AddOwningPipe',
    name: 'AddOwningPipe',
    meta: { isAuth: true, title: '新增截管信息' },
    component: () => import('@/views/AddOwningPipe.vue'),
  },
  {
    path: '/UpdatePipe',
    name: 'UpdatePipe',
    meta: { isAuth: true, title: '修改截管信息' },
    component: () => import('@/views/UpdatePipe.vue'),
  },
  {
    path: '/WeldingMaterial',
    name: 'WeldingMaterial',
    meta: { isAuth: true, title: '焊接材料管理',},
    component: () => import('@/views/WeldingMaterial.vue'),
  },
  {
    path: '/Patching',
    name: 'Patching',
    meta: { isAuth: true, title: '防腐补口' ,},
    component: () => import('@/views/AnticorrosiveCoating.vue'),
  },
  {
    path: '/WeldingOperation',
    name: 'WeldingOperation',
    meta: { isAuth: true, title: '焊口作业',},
    component: () => import('@/views/WeldingOperation.vue'),
  },
  {
    path: '/WeldDetail',
    name: 'WeldDetail',
    meta: { isAuth: true, title: '焊口详细信息' },
    component: () => import('@/views/WeldDetail.vue'),
  },
  {
    path: '/AddWeld',
    name: 'AddWeld',
    meta: { isAuth: true, title: '新增焊接材料信息' },
    component: () => import('@/views/AddWeld.vue'),
  },
  {
    path: '/LineWelding',
    name: 'LineWelding',
    meta: { isAuth: true, title: '线路焊接',},
    component: () => import('@/views/LineWelding.vue'),
  },
  {
    path: '/WeldingInfor',
    name: 'WeldingInfor',
    meta: { isAuth: true, title: '焊接信息' },
    component: () => import('@/views/WeldingInfor.vue'),
  },
  {
    path: '/WeldingPosition',
    name: 'WeldingPosition',
    meta: { isAuth: true, title: '焊口位置',},
    component: () => import('@/views/WeldingPosition.vue'),
  },
  {
    path: '/PositionInfo',
    name: 'PositionInfo',
    meta: { isAuth: true, title: '焊口位置' },
    component: () => import('@/views/PositionInfo.vue'),
  },
  {
    path: '/AnticorrosiveChild',
    name: 'AnticorrosiveChild',
    meta: { isAuth: true, title: '防腐补口焊接信息' },
    component: () => import('@/views/AnticorrosiveChild.vue'),
  },
  {
    path: '/SteelAcceptance',
    name: 'SteelAcceptance',
    meta: { isAuth: true, title: '钢管验收' },
    component: () => import('@/views/SteelAcceptance.vue'),
  },
  {
    path: '/SafetyInspection',
    name: 'SafetyInspection',
    meta: { isAuth: true, title: '安全巡检' },
    component: () => import('@/views/SafetyInspection.vue'),
  },
  {
    path: '/AddSecurityIspection',
    name: 'AddSecurityIspection',
    meta: { isAuth: true, title: '安全巡检新增页面' },
    component: () => import('@/views/AddSecurityIspection.vue'),
  },
  {
    path: '/ViewSafetyInspection',
    name: 'ViewSafetyInspection',
    meta: { isAuth: true, title: '安全巡检查看问题' },
    component: () => import('@/views/ViewSafetyInspection.vue'),
  },
  {
    path: '/ReviewSafety',
    name: 'ReviewSafety',
    meta: { isAuth: true, title: '安全巡检复查' },
    component: () => import('@/views/ReviewSafety.vue'),
  },
  {
    path: '/QualityApp',
    name: 'QualityApp',
    meta: { isAuth: true, title: '质量巡检' },
    component: () => import('@/views/QualityApp.vue'),
  },
  {
    path: '/AddQuality',
    name: 'AddQuality',
    meta: { isAuth: true, title: '质量巡检新增' },
    component: () => import('@/views/AddQuality.vue'),
  },
  {
    path: '/ViewQuality',
    name: 'ViewQuality',
    meta: { isAuth: true, title: '查看质量巡检' },
    component: () => import('@/views/ViewQuality.vue'),
  },
  {
    path: '/ReviewQualityApp',
    name: 'ReviewQualityApp',
    meta: { isAuth: true, title: '质量巡检复查' },
    component: () => import('@/views/ReviewQualityApp.vue'),
  },
  {
    path: '/SteelInfo',
    name: 'SteelInfo',
    meta: { isAuth: true, title: '钢管信息' },
    component: () => import('@/views/SteelInfo.vue'),
  },
  {
    path: '/AcceptanceDetail',
    name: 'AcceptanceDetail',
    meta: { isAuth: true, title: '验收详情页' },
    component: () => import('@/views/AcceptanceDetail.vue'),
  },
  {
    path: '/AcceptanceEdit',
    name: 'AcceptanceEdit',
    meta: { isAuth: true, title: '验收编辑页' },
    component: () => import('@/views/AcceptanceEdit.vue'),
  },
  {
    path: '/AcceptanceDetailTwo',
    name: 'AcceptanceDetailTwo',
    meta: { isAuth: true, title: '二维码-验收详情页' },
    component: () => import('@/views/AcceptanceDetailTwo.vue'),
  },
  {
    path: '/test',
    name: 'test',
    meta: { isAuth: true, title: '测试' },
    component: () => import('@/views/test.vue'),
  },

  /**
   * 路由重定向
   */
  {
    path: '/:pathMatch(.*)*',
    redirect: '/'
  }
];

const router = createRouter({
  history: createWebHistory(),
  routes,
});

router.beforeEach((to: any, from: any, next: any) => {
  const token = localStorage.getItem('token')  //读取token
  if (to.meta.isAuth) { //判断是否需要鉴权
    if (token === null) {
      next({ name: 'Login' })
    } else {
      next()
    }
  } else {
    next()
  }
})

export default router;
